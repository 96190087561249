import NextImage, { ImageProps } from 'next/image';
import { ReactNode } from 'react';
import { Box, ChakraProps } from '@chakra-ui/react';

// next image uses inline-block, which takes line height and then default v-align messes up stuff. This causes images to have 6px more height with 1.4 line-height. Following styles negate this.

interface MhyImageProps {
  children?: ReactNode;
  noRounding?: boolean;
  circle?: boolean;
  containerProps?: ChakraProps;
  objectFit?: ImageProps['objectFit'];
  src?: ImageProps['src'] | null;
  useUnoptimizedImage?: boolean;
  imgHeight?: string;
}

type AllowedStyleProps = Pick<
  ChakraProps,
  | 'borderLeftRadius'
  | 'borderRightRadius'
  | 'borderTopLeftRadius'
  | 'borderTopRightRadius'
  | 'borderBottomRadius'
  | 'borderTopRadius'
  | 'borderBottomRightRadius'
  | 'borderBottomLeftRadius'
>;

const defaultQuality = 80;
const defaultQualitySmallImages = 90;

const asNum = (x?: number | string): number =>
  typeof x === 'string' ? Number.parseInt(x, 10) : x || 0;

const formatSrcThumbnail = (src) => src.replace('-scaled', '');

const calcDefaultQuality = ({
  width,
  height,
}: Pick<ImageProps, 'width' | 'height'>) =>
  Math.max(asNum(width), asNum(height)) > 150
    ? defaultQuality
    : defaultQualitySmallImages;

const Image = ({
  className,
  children,
  noRounding,
  circle,
  src,
  useUnoptimizedImage,
  borderLeftRadius,
  borderRightRadius,
  borderTopRadius,
  borderTopLeftRadius,
  borderTopRightRadius,
  borderBottomRadius,
  borderBottomRightRadius,
  borderBottomLeftRadius,
  imgHeight,
  containerProps,
  ...props
}: MhyImageProps & Omit<ImageProps, 'src'> & AllowedStyleProps) => (
  <Box
    className={[className, 'image'].filter(Boolean).join(' ')}
    lineHeight="1"
    {...containerProps}
    sx={{
      ...(containerProps?.sx ? containerProps.sx : null),
      '> span': { verticalAlign: 'middle' },
      'img:not([aria-hidden="true"])': {
        // eslint-disable-next-line no-nested-ternary
        borderRadius: noRounding ? 0 : circle ? '999px' : 'lg',
        borderLeftRadius,
        borderRightRadius,
        borderTopRadius,
        borderBottomRadius,
        borderBottomRightRadius,
        borderBottomLeftRadius,
        borderTopLeftRadius,
        borderTopRightRadius,
      },
      img: { pointerEvents: 'none', height: imgHeight }, // pointerEvents due to <a><img -structure. refactor into Link if need be
    }}
  >
    {!useUnoptimizedImage ? (
      <NextImage
        quality={calcDefaultQuality(props)}
        objectFit={props.objectFit || 'cover'}
        {...props}
        src={
          src ||
          `https://via.placeholder.com/${asNum(props.width) || 300}x${
            asNum(props.height) || 150
          }`
        }
      />
    ) : (
      <img
        src={formatSrcThumbnail(String(src) || '')}
        alt={props.alt ? props.alt : ''}
        {...props}
        style={{
          objectFit: props.objectFit || 'cover',
          width: `${props.width}px`,
          height: `${props.height}px`,
        }}
      />
    )}
    {children}
  </Box>
);

export default Image;
